import React from 'react'
import { Layout } from '../components/common'

const Work = () => (
  <Layout>
    <div className="container">
      <article className="content" style={{ textAlign: `center` }}>
        <h1>Work Home</h1>
      </article>
    </div>
  </Layout>
)

export default Work
